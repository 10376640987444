import template from './email-message-edit.html';

let Message;
/**
 * @kind component
 * @class ComponentViewModel
 * @description Component for email message edit
 */
class ComponentViewModel
{
	constructor (params, element)
	{
		Message = Grape.dataModel.get('message-manager.message');
		this.editor = null;

		this.params = params;
		this.element = element;
		this.tabs = ko.observableArray([
			{
				title: 'HTML',
				page_id: 'html',
				icon: 'fa-solid fa-file-code'
			},
			{
				title: 'Attachments',
				page_id: 'attachments',
				icon: 'fa-solid fa-upload'
			},
			{
				title: 'Properties',
				page_id: 'advanced',
				icon: 'fa-solid fa-arrow-progress'
			}
		]);
		this.current_page_id = ko.observable('html');
		this.message_input = ko_helper.safe_observable(params.message);
		this.message = ko.observable();
		this.show_save_btn = ko_helper.safe_observable(params.show_save_btn || false);
		this.show_send_btn = ko_helper.safe_observable(params.show_send_btn || false);
		this.templates = ko.observableArray([]);

		this.addresses = ko_helper.safe_observable(params.addresses ? params.addresses : 'true'); // hide or show addresses input

		this.init();
	}

	async init()
	{
		if (!(this.message_input() instanceof Message))
		{
			const msg = new Message();
			await msg.set(this.message_input());
			this.message(msg);
		}
		else
			this.message(this.message_input());

		if (this.message() && (!this.message()?.addressList() || this.message()?.addressList()?.length == 0)){
			await this.message().set({ 'address-full': [
				{ role: "from", type: "email", name: Grape.currentSession.user.email || this.message().channel()?.default_from_address || ''},
				{role: "to", type:"email", name:""}
			] });
		}

		//
		const editor_element = this.element.querySelector('.froala-editor');
		await new Promise((resolve) => {
			this.editor = new FroalaEditor(editor_element, {
				key: Grape.froala.key,
				toolbarButtons: this.getToolbarButtons(),
				attribution: false, // remove "Powered by" watermark
				tableResizerOffset: 10,
				tableResizingLimit: 50
			}, resolve);
		});

		await this.set_message();
		await this.getTemplates();

		// TODO remove
		this.message().getPropertyObservable('subject').subscribe((data) => {
			let str = data;
			if(str && data.length > 30)
				str = data.slice(0, 30)+'...'
			this.message().setPropertyValue('preview', str || '');
		});

		this.message.subscribe(() => this.set_message());
	}

	/* called when message changes */
	async set_message()
	{
		if (this.message())
		{
			let exist = false;
			for (let a of this.message().addressList())
				if(a.role() === 'to')
					exist = true;

			if(!exist)
				this.message().addressList().push({
					address_uuid: null,
					type: 'email',
					name: ko.observable(''),
					role: ko.observable('to')
				});

			await this.set_content();

			let str = this.message().getPropertyValue('subject');
			if (str && str.length > 30)
				str = str.slice(0, 30)+'...'

			this.message().setPropertyValue('preview', str);
		}
	}

	async set_content ()
	{
		let htmlContent = null;

		for (let content of this.message().contentList())
		{
			if (content.name() == 'textBody')
			{
				if (content.property.filename)
					await content.fetchBody();
				else if (content.property.body)
					await content.setBody(content.property);

				if (this.tabs().some(tab => tab.page_id === 'text') == false)
					this.tabs.unshift({
						title: 'Text',
						page_id: 'text',
						icon: 'fa-solid fa-info-circle'
					});
			}
			else if (content.name() == 'htmlBody')
				htmlContent = content;
		}
		const bodyProperty = this.message().propertyList().find(prop => prop.name === 'body');

		if (bodyProperty?.value() && !htmlContent)
		{
			htmlContent = await this.message().addContent('htmlBody', 'body', { type: 'text', subtype: 'html' });
			htmlContent.setBody(bodyProperty.value());
		}
		else if (!htmlContent)
		{
			htmlContent = await this.message().addContent('htmlBody', 'body', { type: 'text', subtype: 'html' });
			htmlContent.setBody('<p> </p>');
		}

		if (this.editor.edit !== undefined && this.message().status() == 'Sent')
			this.editor.edit.off();

		if (htmlContent)
			if (htmlContent.body())
			{
				let html = this.updateInlineContent(htmlContent.body());
				this.editor.html.set(html);
			}
	}

	updateInlineContent (html)
	{
		const tempDiv = document.createElement('div');
		tempDiv.innerHTML = html;

		const imgTags = tempDiv.getElementsByTagName('img');
		const embedTags = tempDiv.getElementsByTagName('embed');

		for (let image of [...imgTags, ...embedTags])
		{
			if (image.hasAttribute('data-content-name'))
			{
				let name = image.getAttribute('data-content-name');
				image.src = `/api/messages/message/content?message_uuid=${this.message().message_uuid()}\&name=${name}`;
			}
			else if (image.getAttribute('src').startsWith('cid'))
			{
				const src = image.getAttribute('src');
				const cid = src.slice(4);

				image.src = `/api/messages/message/content?message_uuid=${this.message().message_uuid()}\&content_id=${cid}`;
			}
			else if (image.hasAttribute('data-content-id'))
			{
				let content_id = image.getAttribute('data-content-id');
				image.src = `/api/messages/message/content?message_uuid=${this.message().message_uuid()}\&content_id=${content_id}`;
			}

			// convert svg type from img to embed tag
			if (image.hasAttribute('data-content-subtype') && image.tagName == 'IMG')
			{
				let subtype = image.getAttribute('data-content-subtype');

				if (subtype.includes('svg'))
				{
					let embed = document.createElement('embed');

					for (let attr of image.attributes)
						embed.setAttribute(attr.name, attr.value);

					image.parentNode.replaceChild(embed, image);
				}
			}
		}

		const updatedHtmlString = tempDiv.innerHTML;

		return updatedHtmlString;
	}

	async getTemplates ()
	{
		//TODO replace with cache
		try
		{
			const result = await Grape.fetches.getJSON(`/api/record`, {
				schema: 'messages',
				table: 'v_templates',
				filter: [
					{
						field: 'driver_name',
						operand: '=',
						value: 'Email'
					}
				]
			});

			if (result.status == 'OK')
				this.templates(result.records || []);
		} catch (error) {
			Grape.alerts.alert({ type: 'error', title: 'Error', message: error.message });
			console.error(error);
		}
	}

	getToolbarButtons ()
	{
		return [
			[
				'html',
				'fullscreen',
				'undo',
				'redo'
			],
			[
				'bold',
				'italic',
				'underline',
				'textColor',
				'backgroundColor',
				'inlineStyle',
				'clearFormatting'
			],
			[
				'fontFamily',
				'fontSize'
			],
			[
				'alignLeft',
				'alignCenter',
				'alignRight',
				'alignJustify'
			],
			[
				'formatOL',
				'formatUL',
				'indent',
				'outdent'
			],
			[
				'paragraphFormat',
				'paragraphStyle'
			],
			[
				'insertLink',
				'insertImage',
				'insertTable',
				'insertFile',
				'insertAnchorBtn',
				'insertContainer'
			],
			[
				'trackChanges',
				'markdown'
			]
		]
	}

	load_tab (page_id)
	{
		this.current_page_id(page_id);
	}

	async onContentFileUpload(event)
	{
		await this.message().uploadContent(event.target.files);
		await this.message().fetch(['content.property']);
	}

	async getHtml ()
	{
		const html = await this.message().getContentTextByName('htmlBody');
		return html;
	}

	async btnSend ()
	{
		if (!(await Grape.alerts.confirm({ type: 'warning', title: 'Confirm',  message: 'Are you sure you want to send this message?' })))
			return;

		await this.message().setPropertyValue('envelopedate', new Date().toISOString());
		await this.message().save();

		const contentList = this.message().contentList();
		for(let content of contentList)
		{
			if (content.message_uuid() === undefined)
				content.set_message_uuid(this.message().message_uuid());

			if (content.name() === 'htmlBody')
			{
				let html = await this.updateImageAttributes(this.editor.html.get());
				content.setBody(html);
			}

			if (content.body())
				await content.saveBody();
		}

		await this.message().send();
		this.params.sendCallback();
	}

	async btnSave ()
	{
		await this.message().save();

		const contentList = this.message().contentList();
		for (let content of contentList)
		{
			if (content.message_uuid() === undefined)
				content.set_message_uuid(this.message().message_uuid());

			if (content.name() === 'htmlBody')
			{
				let html = await this.updateImageAttributes(this.editor.html.get());
				content.setBody(html);
			}

			if (content.body())
				await content.saveBody();
		}

		this.params.saveCallback();
	}

	// TODO remove classes and replace with inline styling
	async updateImageAttributes (html)
	{
		const tempDiv = document.createElement('div');
		tempDiv.innerHTML = html;

		const embedTags = tempDiv.getElementsByTagName('embed');
		// convert svg type from embed to img tag
		for (let embed of embedTags)
		{
			const image = document.createElement('img');

			for (let attr of embed.attributes)
				image.setAttribute(attr.name, attr.value);

			embed.parentNode.replaceChild(image, embed);
		}

		const imgTags = tempDiv.getElementsByTagName('img');


		for (let image of imgTags)
		{
			// TODO get cid using content_nr in contentList
			if (image.hasAttribute('data-content-name') == false)
			{
				let content = await this.uploadInlineContent(image.src);

				if (content.content_id())
					image.setAttribute('src', `cid:${content.content_id()}`);

				image.setAttribute('data-content-name', content.name());
				image.setAttribute('data-content-subtype', content.subtype());
			}
			else
			{
				let content_name = image.getAttribute('data-content-name');
				let content_id = this.message().contentList().find((cont) => cont.name() === content_name);

				image.setAttribute('src', `cid:${content_id.content_id()}`);
			}
		}

		// Convert the updated HTML back to a string
		const updatedHtmlString = tempDiv.innerHTML;

		return updatedHtmlString;
	}

	async uploadInlineContent (base64String)
	{
		try
		{
			const response = await fetch(base64String);
			const blob = await response.blob();

			let [type, subtype] = blob.type.split('/');
			let name = this.findNextFroalaContentName();
			let htmlContent = await this.message().addContent(name, 'inline', { type: type, subtype: subtype });
			htmlContent.setBody(blob);

			if (htmlContent.message_uuid() === undefined)
				htmlContent.set_message_uuid(this.message().message_uuid());

			await htmlContent.saveBody();

			return htmlContent;
		} catch (error) {
			Grape.alerts.alert({ type: 'error', title: 'Error', message: error.message });
			console.error(error);
		}
	}

	findNextFroalaContentName ()
	{
		let maxNumber = 0;

		for (let content of this.message().contentList())
		{
			const name = content.name();
			if (name.startsWith("froalaInlineContent"))
			{
				const numberPart = name.replace("froalaInlineContent", "");
				const number = parseInt(numberPart, 10);
				if (!isNaN(number) && number > maxNumber)
					maxNumber = number;
			}
		}

		return `froalaInlineContent${maxNumber + 1}`
	}

	deleteContent (content)
	{
		let updatedContentList = this.message().contentList().filter(x => x.name() !== content.name());
		this.message().contentList(updatedContentList);
	}
}

export default {
	name: 'email-message-edit',
	viewModel: ComponentViewModel,
	module_type: 'ko',
	template: template
};
